import React from 'react';

import userReducer from './modal.reducer';

export type ModalStateType = {
  isOpen: boolean,
  content: string,
  heading: string,
  onComplete: () => void
}

export const ModalContext = React.createContext<{
  state?: ModalStateType;
  dispatch?: React.Dispatch<any>;
}>({});

const INITIAL_STATE: ModalStateType = {
  isOpen: false,
  content: null,
  heading: null,
  onComplete: null
};

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(userReducer, INITIAL_STATE);

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {children}
    </ModalContext.Provider>
  );
};