import { styled, withStyle } from 'baseui';
import {
  StyledTable as BaseStyledTable,
  StyledHeadCell as BaseStyledHeadCell,
  StyledBodyCell as BaseStyledCell,
} from 'baseui/table-grid';

import { Row as Rows, Col as Column } from 'components/FlexBox/FlexBox';

const buttonRadius = {
  borderTopLeftRadius: '3px',
  borderTopRightRadius: '3px',
  borderBottomRightRadius: '3px',
  borderBottomLeftRadius: '3px'
}

export const GeneralErrorStyle = {
  Body: {
    style: () => ({ width: '100%' })
  }
}

export const ButtonStyle = {
  BaseButton: {
    style: () => ({
      width: '50%',
      ...buttonRadius,
    }),
  },
}

export const DashedFullWidthButtonStyle = {
  BaseButton: {
    style: ({ $theme }) => ({
      width: "100%",
      ...buttonRadius,
      border: "2px dashed " + $theme.colors.borderF1,
      color: $theme.colors.textNormal,
      marginTop: "10px"
    })
  }
}

export const ButtonError = {
  BaseButton: {
    style: ({ $theme }) => ({
      color: $theme.colors.red400,
    })
  }
}

export const TertiaryButtonDanger = {
  BaseButton: {
    style: ({ $theme }) => ({
      width: '50%',
      ...buttonRadius,
      marginRight: '15px',
      color: $theme.colors.red400,
    }),
  },
}

export const MinimalButtonDanger = {
  BaseButton: {
    style: ({ $theme }) => ({
      ...buttonRadius,
      marginRight: '15px',
      color: $theme.colors.red400,
    }),
  },
}

export const PrimaryButtonDanger = {
  BaseButton: {
    style: ({ $theme }) => ({
      ...buttonRadius,
      backgroundColor: $theme.colors.red400,
      color: $theme.colors.white,
    })
  },
}

export const AddButtonStyle = {
  BaseButton: {
    style: () => ({
      width: '100%',
      ...buttonRadius,
    }),
  },
};

export const HeaderWrapperStyle = {
  marginBottom: 30,
  boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)'
};

export const SelectStyle = {
  Placeholder: {
    style: ({ $theme }) => {
      return {
        ...$theme.typography.fontBold14,
        color: $theme.colors.textNormal,
      };
    },
  },
  DropdownListItem: {
    style: ({ $theme }) => {
      return {
        ...$theme.typography.fontBold14,
        color: $theme.colors.textNormal,
      };
    },
  },
  Popover: {
    props: {
      overrides: {
        Body: {
          style: { zIndex: 5 },
        },
      },
    },
  },
};

export const CheckboxStyle = {
  Checkmark: {
    style: {
      borderTopWidth: '2px',
      borderRightWidth: '2px',
      borderBottomWidth: '2px',
      borderLeftWidth: '2px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
  },
};

export const IconButtonStyle = {
  BaseButton: {
    style: {
      width: '28px',
      height: '28px'
    }
  }
}

export const TableWrapper = styled('div', () => ({
  width: '100%',
  // height: '600px',
}));

export const StyledTable = withStyle(BaseStyledTable, () => ({
  borderTopLeftRadius: '0 !important',
  borderTopRightRadius: '0 !important',
  borderBottomLeftRadius: '0 !important',
  borderBottomRightRadius: '0 !important',
  alignContent: 'start',
}));

export const StyledHeadCell = withStyle(BaseStyledHeadCell, () => ({
  fontFamily: "'Lato', sans-serif",
  fontWeight: 700,
  color: '#161F6A !important',
  alignItems: 'center',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  borderTopColor: 'rgba(0, 0, 0, 0.12)',
  borderRightColor: 'rgba(0, 0, 0, 0.12)',
  borderBottomColor: 'rgba(0, 0, 0, 0.12)',
  borderLeftColor: 'rgba(0, 0, 0, 0.12)',
  alignSelf: 'start',
}));

export const StyledCell = withStyle(BaseStyledCell, () => ({
  fontFamily: "'Lato', sans-serif",
  fontWeight: 400,
  color: '#161F6A !important',
  alignSelf: 'center',
}));

export const StyledHeadCellCenter = withStyle(BaseStyledHeadCell, () => ({
  fontFamily: "'Lato', sans-serif",
  fontWeight: 700,
  color: '#161F6A !important',
  alignItems: 'center',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  borderTopColor: 'rgba(0, 0, 0, 0.12)',
  borderRightColor: 'rgba(0, 0, 0, 0.12)',
  borderBottomColor: 'rgba(0, 0, 0, 0.12)',
  borderLeftColor: 'rgba(0, 0, 0, 0.12)',
  alignSelf: 'start',
  justifyContent: 'center',
}));

export const StyledCellCenter = withStyle(BaseStyledCell, () => ({
  fontFamily: "'Lato', sans-serif",
  fontWeight: 400,
  color: '#161F6A !important',
  alignSelf: 'center',
  justifyContent: 'center',
}));

export const Status = styled('div', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  display: 'flex',
  alignItems: 'center',
  lineHeight: '1',
  textTransform: 'capitalize',

  ':before': {
    content: '""',
    width: '10px',
    height: '10px',
    display: 'inline-block',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    backgroundColor: $theme.borders.borderE6,
    marginRight: '10px',
  },
}));

export const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0,
    },
  },
}));

export const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}));

export const ProgressStepsOverride = {
  Root: {
    style: () => ({
      width: "100%"
    })
  }
}