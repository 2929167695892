import axios from 'axios';
import { API_URL, BASE_URL } from 'settings/constants';

export interface Role {
  id: number,
  name: string
}

export interface User {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  role: Role
}

export const getMe = async (token: string): Promise<User> => {
  console.log("[API] Getting user details");
  return new Promise<User>((resolve, reject) => {
    axios.get(BASE_URL + API_URL.USERS_ME, { headers: { Authorization: "Bearer " + token } })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export const authLocal = async (
  data: { identifier: string, password: string }
): Promise<{ user: User, jwt: string }> => {
  return new Promise<{ user: User, jwt: string }>((resolve, reject) => {
    axios.post(BASE_URL + API_URL.AUTH_LOCAL, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export const updateUser = async (
  id: number, token: string, first_name: string, last_name: string, email: string
): Promise<{ user: User, jwt: string }> => {
  return new Promise<{ user: User, jwt: string }>((resolve, reject) => {
    const url = BASE_URL + API_URL.USERS + "/" + id;
    console.log("url: ", url);
    axios.put(url,
      { first_name, last_name, email },
      { headers: { 'Authorization': 'Bearer ' + token } })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export const changePassword = async (
  id: number, token: string, current_password: string, new_password: string, confirm_password: string
): Promise<{ user: User, jwt: string }> => {
  return new Promise<{ user: User, jwt: string }>((resolve, reject) => {
    const url = BASE_URL + API_URL.AUTH_RESET_PASSWORD;
    axios.post(url,
      { user: id, current_password: current_password, password: new_password, confirm_password: confirm_password },
      { headers: { 'Authorization': 'Bearer ' + token } })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}