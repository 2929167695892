import ReactDOM from 'react-dom';
import { BaseProvider } from 'baseui';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import "rc-pagination/assets/index.css";
import "./assets/styles/pagination.css";
import './theme/global.css';

import * as serviceWorker from './serviceWorker';
import Routes from './routes';
import { theme } from './theme';
import { UserProvider } from 'context/user/user.provider';
import { ModalProvider } from 'context/modal/modal.provider';

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
});

function App() {
  const engine = new Styletron();

  return (
    <ApolloProvider client={client as any}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <BrowserRouter>
            <UserProvider>
              <ModalProvider>
                <ToastContainer
                  position="top-right" autoClose={10000} hideProgressBar={false} newestOnTop={false}
                  closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover
                />
                <Routes />
              </ModalProvider>
            </UserProvider>
          </BrowserRouter>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
