import React from 'react';

import userReducer from './user.reducer';
import { User } from "../../types/user";

export type UserStateType = {
  user: User,
  token: string,
  errorMessage: string,
  isAuthenticated: boolean
}

export const UserContext = React.createContext<{
  auth?: UserStateType;
  authDispatch?: React.Dispatch<any>;
}>({});

const user: User = JSON.parse(localStorage.getItem('user'));
const token: string = localStorage.getItem('token');
const isAuthenticated: boolean = JSON.parse(localStorage.getItem('isAuthenticated'));

const INITIAL_STATE: UserStateType = {
  user: null || user,
  token: null || token,
  errorMessage: null,
  isAuthenticated: false || isAuthenticated
};

export const UserProvider = ({ children }) => {
  const [auth, authDispatch] = React.useReducer(userReducer, INITIAL_STATE);
  return (
    <UserContext.Provider value={{ auth, authDispatch }}>
      {children}
    </UserContext.Provider>
  );
};