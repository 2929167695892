import { ModalStateType } from "./modal.provider";

type ActionType =
  | { type: 'OPEN'; payload: { content: string, heading?: string, onComplete: () => void } }
  | { type: 'CLOSE' }

export default function userReducer(state: ModalStateType, action: ActionType) {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        content: action.payload.content,
        heading: action.payload.heading,
        onComplete: action.payload.onComplete,
        isOpen: true,
      };
    case 'CLOSE':
      return { ...state, heading: null, content: null, onComplete: null, isOpen: false };
    default:
      return state;
  }
}