// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const PRODUCTS = '/products';
export const CATEGORY = '/category';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const DELIVERY_ORDERS = '/delivery-orders';
export const PICK_UP_ORDERS = '/pick-up-orders';
export const CUSTOMERS = '/customers';
export const COUPONS = '/coupons';
export const SETTINGS = '/settings';
export const MEDIA = '/media';
export const STAFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '₱';

// Errors
export const PLEASE_REFRESH = "Kindly refresh the page and try again.";
export const FORBIDDEN_ERROR = "You do not have permission for this. Kindly request an access from the administrator first then try again.";


// API
export const BASE_URL = "https://api.cabralbicolandia.com";
export const API_URL = {
  ADDRESSES: "/addresses",
  AUTH_LOCAL: "/auth/local",
  AUTH_RESET_PASSWORD: "/auth/reset-password",
  CATEGORIES: "/categories",
  DELIVERY_ORDERS: '/delivery-orders',
  ORDERS: "/orders",
  ORDER_ITEMS: "/order-items",
  PICK_UP_ORDERS: '/pick-up-orders',
  PRODUCTS: "/products",
  PRODUCT_QUANTITY_TAG: "/product-quantity-tags",
  PRODUCT_VARIANTS: "/product-variants",
  STATUSES: "/statuses",
  UPLOAD: "/upload",
  UPLOAD_FILES: "/upload/files",
  USERS: "/users",
  USERS_ME: "/users/me",
}
export const STATUS_CODE = {
  OK: 200,
  BAD_REQUEST: 400,
  UNATHORIZED: 401,
  FORBIDDEN: 403,
}

// Others
export const MAX_TABLE_ROWS = 12;

export const ucwords = (str?: string): string => {
  if (!str) return null;
  str = str.toLowerCase();
  return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, (s) => s.toUpperCase());
}