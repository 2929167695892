import React from "react";
import { Modal as BaseModal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal";
import { KIND as ButtonKind, SIZE as ButtonSize } from "baseui/button";

import { ModalContext } from "context/modal/modal.provider";
import { MinimalButtonDanger, PrimaryButtonDanger } from "assets/styles/global.styles";

const Modal: React.FC<{}> = () => {
  const { state, dispatch } = React.useContext(ModalContext);
  const [isLoading, toggleLoading] = React.useState<boolean>(false);

  const close = () => dispatch({ type: 'CLOSE' });

  const onComplete = async () => {
    toggleLoading(true);
    await state.onComplete();
    toggleLoading(false);
  }

  return (
    <BaseModal
      onClose={close}
      closeable={!isLoading}
      isOpen={state.isOpen}
      animate={true}
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: {
            zIndex: 3
          }
        }
      }}
    >
      {state.heading && (<ModalHeader>{state.heading}</ModalHeader>)}
      <ModalBody>{state.content}</ModalBody>
      <ModalFooter>
        {!isLoading && (
          <ModalButton
            kind={ButtonKind.minimal}
            size={ButtonSize.compact}
            overrides={MinimalButtonDanger}
            onClick={close}
          >
            Cancel
          </ModalButton>
        )}
        <ModalButton
          kind={ButtonKind.tertiary}
          size={ButtonSize.compact}
          overrides={PrimaryButtonDanger}
          onClick={onComplete}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Delete
        </ModalButton>
      </ModalFooter>
    </BaseModal>
  );
}

export default Modal;
