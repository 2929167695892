import React from 'react';

import dataReducer from './data.reducer';
import { Category } from 'types/category';
import { ProductResponse } from 'types/product';
import { OrderResponse, OrderStatus } from 'types/order';

export type DataStateType = {
  deliveryOrderStatuses: Array<OrderStatus>,
  deliveryOrders: OrderResponse,
  pickUpOrderStatuses: Array<OrderStatus>,
  pickUpOrders: OrderResponse,
  categories: Array<Category>,
  products: ProductResponse
}

export const DataContext = React.createContext<{
  state?: DataStateType;
  dispatch?: React.Dispatch<any>;
}>({});

const INITIAL_STATE: DataStateType = {
  deliveryOrderStatuses: null,
  deliveryOrders: { items: null, total: null, error: null },
  pickUpOrderStatuses: null,
  pickUpOrders: { items: null, total: null, error: null },
  categories: null,
  products: { items: null, total: null, error: null },
};

export const DataProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(dataReducer, INITIAL_STATE);
  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};