import React from "react";
import { Redirect, Route } from "react-router-dom";

import { UserContext } from "context/user/user.provider";
import { getMe, User } from "types/user";

const PrivateRoute = ({ children, ...rest }) => {
  const { auth, authDispatch } = React.useContext(UserContext);

  const checkToken = React.useCallback(async () => {
    if (auth?.token && auth?.user) {
      try {
        const user: User = await getMe(auth.token);
        if (user.id !== auth.user.id) authDispatch({ type: 'LOGOUT' });
      } catch (error) {
        authDispatch({ type: 'LOGOUT' });
      }
    }
  }, [authDispatch, auth]);

  React.useEffect(() => {
    checkToken();
  }, [checkToken]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isAuthenticated ? (children) : (<Redirect to={{ pathname: '/login', state: { from: location } }} />)
      }
    />
  );
}

export default PrivateRoute;