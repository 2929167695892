import { User } from "types/user";
import { UserStateType } from "./user.provider";

type ActionType =
  | { type: 'LOGIN_SUCCESS'; payload: { user: User, token: string } }
  | { type: 'LOGOUT'; payload: any }
  | { type: 'UPDATE_USER', payload: { user: User, token: string } }

export default function userReducer(state: UserStateType, action: ActionType) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('isAuthenticated', JSON.stringify(true));
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
      };
    case 'LOGOUT':
      localStorage.clear();
      return { ...state, user: null, token: null, isAuthenticated: false };
    case 'UPDATE_USER':
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    default:
      return state;
  }
}