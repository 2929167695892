import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Modal from 'components/modal';
import {
  LOGIN,
  PRODUCTS,
  CATEGORY,
  DASHBOARD,
  SETTINGS,
  STAFF_MEMBERS,
  MEDIA,
  DELIVERY_ORDERS,
  PICK_UP_ORDERS,
} from 'settings/constants';
import { InLineLoader } from 'components/InlineLoader/InlineLoader';
import { DataProvider } from 'context/data/data.provider';
import PrivateRoute from 'components/private-route';

const Products = React.lazy(() => import('containers/Products/Products'));
const AdminLayout = React.lazy(() => import('containers/Layout/Layout'));
const Dashboard = React.lazy(() => import('containers/Dashboard/Dashboard'));
const Category = React.lazy(() => import('containers/categories'));
const Settings = React.lazy(() => import('containers/Settings/Settings'));
const MediaLibrary = React.lazy(() => import('containers/media'));
const StaffMembers = React.lazy(() => import('containers/StaffMembers/StaffMembers'));
const Login = React.lazy(() => import('containers/Login/Login'));
const NotFound = React.lazy(() => import('containers/NotFound/NotFound'));
const DeliveryOrders = React.lazy(() => import('containers/delivery-orders'));
const PickUpOrders = React.lazy(() => import('containers/pick-up-orders'));

const Routes = () => {
  return (
    <React.Suspense fallback={<InLineLoader />}>
      <Switch>
        <PrivateRoute exact={true} path={DASHBOARD}>
          <DataProvider>
            <AdminLayout>
              <React.Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </React.Suspense>
            </AdminLayout>
          </DataProvider>
        </PrivateRoute>
        <PrivateRoute path={PRODUCTS}>
          <DataProvider>
            <AdminLayout>
              <React.Suspense fallback={<InLineLoader />}>
                <Products />
              </React.Suspense>
            </AdminLayout>
          </DataProvider>
        </PrivateRoute>
        <PrivateRoute path={CATEGORY}>
          <DataProvider>
            <AdminLayout>
              <React.Suspense fallback={<InLineLoader />}>
                <Category />
              </React.Suspense>
            </AdminLayout>
          </DataProvider>
        </PrivateRoute>
        <PrivateRoute path={SETTINGS}>
          <AdminLayout>
            <React.Suspense fallback={<InLineLoader />}>
              <Settings />
            </React.Suspense>
          </AdminLayout>
        </PrivateRoute>
        <PrivateRoute path={STAFF_MEMBERS}>
          <AdminLayout>
            <React.Suspense fallback={<InLineLoader />}>
              <StaffMembers />
            </React.Suspense>
          </AdminLayout>
        </PrivateRoute>
        <PrivateRoute path={MEDIA}>
          <DataProvider>
            <AdminLayout>
              <React.Suspense fallback={<InLineLoader />}>
                <MediaLibrary />
              </React.Suspense>
            </AdminLayout>
          </DataProvider>
        </PrivateRoute>
        <PrivateRoute path={MEDIA}>
          <DataProvider>
            <AdminLayout>
              <React.Suspense fallback={<InLineLoader />}>
                <MediaLibrary />
              </React.Suspense>
            </AdminLayout>
          </DataProvider>
        </PrivateRoute>
        <PrivateRoute path={DELIVERY_ORDERS}>
          <DataProvider>
            <AdminLayout>
              <React.Suspense fallback={<InLineLoader />}>
                <DeliveryOrders />
              </React.Suspense>
            </AdminLayout>
          </DataProvider>
        </PrivateRoute>
        <PrivateRoute path={PICK_UP_ORDERS}>
          <DataProvider>
            <AdminLayout>
              <React.Suspense fallback={<InLineLoader />}>
                <PickUpOrders />
              </React.Suspense>
            </AdminLayout>
          </DataProvider>
        </PrivateRoute>
        <Route path={LOGIN}>
          <Login />
        </Route>
        <Route component={NotFound} />
      </Switch>
      <Modal />
    </React.Suspense>
  );
};

export default Routes;
