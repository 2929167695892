import _ from "lodash";
import { Category } from "types/category";
import { Order, OrderResponse, OrderStatus } from "types/order";
import { Product, ProductResponse } from "types/product";
import { DataStateType } from "./data.provider";

export const DATA_ACTION_TYPE = {
  POPULATE_CATEGORIES: 'POPULATE_CATEGORIES',
  ADD_CATEGORY: 'ADD_CATEGORY',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  POPULATE_DATA: 'POPULATE_DATA',
  POPULATE_PRODUCTS: 'POPULATE_PRODUCTS',
  CLEAR_PRODUCTS: 'CLEAR_PRODUCTS',
  ADD_PRODUCT: 'ADD_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  POPULATE_DELIVERY_ORDER_STATUS: 'POPULATE_DELIVERY_ORDER_STATUS',
  POPULATE_DELIVERY_ORDERS: 'POPULATE_DELIVERY_ORDERS',
  CLEAR_DELIVERY_ORDERS: 'CLEAR_DELIVERY_ORDERS',
  UPDATE_DELIVERY_ORDER_STATUS: 'UPDATE_DELIVERY_ORDER_STATUS',
  POPULATE_PICK_UP_ORDER_STATUS: 'POPULATE_PICK_UP_ORDER_STATUS',
  POPULATE_PICK_UP_ORDERS: 'POPULATE_PICK_UP_ORDERS',
  CLEAR_PICK_UP_ORDERS: 'CLEAR_PICK_UP_ORDERS',
  UPDATE_PICK_UP_ORDER_STATUS: 'UPDATE_PICK_UP_ORDER_STATUS',
}

type ActionType =
  | { type: 'POPULATE_CATEGORIES'; payload: { items: Array<Category> } }
  | { type: 'ADD_CATEGORY'; payload: { item: Category } }
  | { type: 'UPDATE_CATEGORY'; payload: { item: Category } }
  | { type: 'DELETE_CATEGORY'; payload: { item: Category } }
  | { type: 'POPULATE_DATA'; payload: { updatedState: DataStateType } }
  | { type: 'POPULATE_PRODUCTS'; payload: { response: ProductResponse } }
  | { type: 'CLEAR_PRODUCTS'; }
  | { type: 'ADD_PRODUCT'; payload: { product: Product } }
  | { type: 'UPDATE_PRODUCT'; payload: { product: Product } }
  | { type: 'POPULATE_DELIVERY_ORDER_STATUS'; payload: { items: Array<OrderStatus> } }
  | { type: 'POPULATE_DELIVERY_ORDERS'; payload: { response: OrderResponse } }
  | { type: 'CLEAR_DELIVERY_ORDERS'; }
  | { type: 'UPDATE_DELIVERY_ORDER_STATUS'; payload: { id: number, updatedOrder: Order } }
  | { type: 'POPULATE_PICK_UP_ORDER_STATUS'; payload: { items: Array<OrderStatus> } }
  | { type: 'POPULATE_PICK_UP_ORDERS'; payload: { response: OrderResponse } }
  | { type: 'CLEAR_PICK_UP_ORDERS'; }
  | { type: 'UPDATE_PICK_UP_ORDER_STATUS'; payload: { id: number, updatedOrder: Order } }

const addCategory = (state: DataStateType, item: Category): DataStateType => {
  const newItems = state.categories.concat(item);
  return { ...state, categories: newItems };
}
const updateCategory = (state: DataStateType, item: Category): DataStateType => {
  const updatedCategories = [...state.categories];
  updatedCategories.forEach((category, index) => {
    if (category.id === item.id) updatedCategories[index] = item;
  })
  return { ...state, categories: updatedCategories }
}
const deleteCategory = (state: DataStateType, item: Category): DataStateType => {
  const updatedCategories = [...state.categories];
  _.remove(updatedCategories, (category) => item.id === category.id);
  return { ...state, categories: updatedCategories };
}
const updateDeliveryOrderStatus = (state: DataStateType, item: Order): DataStateType => {
  const updatedDeliveryOrders = [...state.deliveryOrders.items];
  updatedDeliveryOrders.forEach((order) => {
    if (order.id === item.id) order.status = item.status;
  })
  return { ...state, deliveryOrders: { ...state.deliveryOrders, items: updatedDeliveryOrders } }
}
const updatePickUpOrderStatus = (state: DataStateType, item: Order): DataStateType => {
  const updatedPickUpOrders = [...state.pickUpOrders.items];
  updatedPickUpOrders.forEach((order) => {
    if (order.id === item.id) order.status = item.status;
  })
  return { ...state, pickUpOrders: { ...state.pickUpOrders, items: updatedPickUpOrders } }
}
const addProduct = (state: DataStateType, product: Product): DataStateType => {
  const updatedItems = [product, ...state.products.items];
  updatedItems.splice(-1, 1);
  return { ...state, products: { ...state.products, items: updatedItems } };
}
const updateProduct = (state: DataStateType, product: Product): DataStateType => {
  const updatedItems = [product, ...state.products.items];
  updatedItems.splice(-1, 1);
  return { ...state, products: { ...state.products, items: updatedItems } };
}

export default function dataReducer(state: DataStateType, action: ActionType): DataStateType {
  switch (action.type) {
    // CATEGORIES
    case 'POPULATE_CATEGORIES':
      return { ...state, categories: action.payload.items };
    case 'ADD_CATEGORY':
      return addCategory(state, action.payload.item);
    case 'UPDATE_CATEGORY':
      return updateCategory(state, action.payload.item);
    case 'DELETE_CATEGORY':
      return deleteCategory(state, action.payload.item);
    // DELIVERY ORDER
    case 'POPULATE_DELIVERY_ORDER_STATUS':
      return { ...state, deliveryOrderStatuses: action.payload.items }
    case 'POPULATE_DELIVERY_ORDERS':
      return { ...state, deliveryOrders: action.payload.response }
    case 'CLEAR_DELIVERY_ORDERS':
      return { ...state, deliveryOrders: { items: null, total: null, error: null } }
    case 'UPDATE_DELIVERY_ORDER_STATUS':
      return updateDeliveryOrderStatus(state, action.payload.updatedOrder);
    // PICK_UP ORDER
    case 'POPULATE_PICK_UP_ORDER_STATUS':
      return { ...state, pickUpOrderStatuses: action.payload.items }
    case 'POPULATE_PICK_UP_ORDERS':
      return { ...state, pickUpOrders: action.payload.response }
    case 'CLEAR_PICK_UP_ORDERS':
      return { ...state, pickUpOrders: { items: null, total: null, error: null } }
    case 'UPDATE_PICK_UP_ORDER_STATUS':
      return updatePickUpOrderStatus(state, action.payload.updatedOrder);
    // DATA
    case 'POPULATE_DATA':
      return { ...state, ...action.payload.updatedState };
    // PRODUCTS
    case 'POPULATE_PRODUCTS':
      return { ...state, products: action.payload.response };
    case 'CLEAR_PRODUCTS':
      return { ...state, products: { items: null, total: null, error: null } };
    case 'ADD_PRODUCT':
      return addProduct(state, action.payload.product);
    case 'UPDATE_PRODUCT':
      return updateProduct(state, action.payload.product);
    // case 'ADD_PRODUCT':
    //   return addProduct(state, action.payload.item);
    default:
      return state;
  }
}